let serviceList = [
  {
    title: "Machine Learning",
    des: "Utilise our machine learning expertise to automate complex decision-making and predictive analytics",
    img: "assets/img/service-icon/7.svg",
  },
  {
    title: "Computer Vision",
    des: "Revolutionise your operational capabilities with our computer vision solutions for real-time image processing",
    img: "assets/img/service-icon/8.svg",
  },
  {
    title: "Natural Language Processing",
    des: "Transform how you interact with users utilizing our advanced natural language processing technologies",
    img: "assets/img/service-icon/9.svg",
  },
  {
    title: "Neural Solutions",
    des: "Solve intricate challenges across various industries with our scalable and efficient neural network solutions",
    img: "assets/img/service-icon/10.svg",
  },
  {
    title: "Web Application with AI",
    des: "Enhance your digital presence with our AI-driven web applications tailored for dynamic user engagement",
    img: "assets/img/service-icon/11.svg",
  },

  {
    title: "Android + iOS",
    des: "Build feature-rich Android and iOS applications integrated with artificial intelligence to improve user experience",
    img: "assets/img/service-icon/12.svg",
  },
];

export default serviceList;
